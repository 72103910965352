import React, { useEffect, useState } from "react";
import dynamic from 'next/dynamic';
import { LocalStorage } from "../../utils/LocalStorage";

const Cookies = dynamic(() => import('../Cookies'), {
  ssr: false,
});

const FirstRunPopupContainer: React.FC = () => {
  const [isShowCookiePopup, setIsShowCookiePopup] = useState(false);

  const showCookieIfNeed = () => {
    if (!LocalStorage.getAllowedCookie()) {
      setIsShowCookiePopup(true);
    }
  };

  const hideCookiePopup = () => setIsShowCookiePopup(false);

  useEffect(() => {
    showCookieIfNeed();
  }, [isShowCookiePopup]);

  if (isShowCookiePopup) {
    return <Cookies hideCookiePopup={hideCookiePopup} />;
  }

  return null;
};

export default FirstRunPopupContainer;
